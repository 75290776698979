import React from 'react';
import { observer } from 'mobx-react-lite';
import { SuccessAlert } from './success-alert';
import { ErrorAlert } from './error-alert';
import { WarningAlert } from './warning-alert';
import { Wrapper } from './alerts.styled';
import { useRootStore } from '../../../providers/root-store-provider';
import { AlertPlacementEnum } from '../../../models/enums/alert-placement-enum';

interface AlertsPropsInterface {
    email?: string;
}

export const Alerts: React.FC<AlertsPropsInterface> = observer(({ email }: AlertsPropsInterface) => {
    const { themeStore, alertStore } = useRootStore();
    const { layout, showBar } = themeStore;

    if (alertStore.alertPlacement !== AlertPlacementEnum.Default) {
        return null;
    }

    return (
        <Wrapper layout={layout} showBar={showBar}>
            <SuccessAlert />
            <ErrorAlert email={email} />
            <WarningAlert />
        </Wrapper>
    );
});
