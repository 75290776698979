import Link from 'next/link';
import React from 'react';
import { Wrapper } from './alert-button.styled';

export type AlertButtonPropsInterface = {
    href?: string;
    children: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
};

export const AlertButton: React.FC<AlertButtonPropsInterface> = ({ href, children, onClick }) => {
    const ButtonStyled = <Wrapper>{children}</Wrapper>;

    if (!href) {
        return <a onClick={onClick}>{ButtonStyled}</a>;
    }

    return (
        <Link href={href} passHref>
            <a>{ButtonStyled}</a>
        </Link>
    );
};
