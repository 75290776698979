import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../../../providers/root-store-provider';
import { Alert } from '../alert/alert';
import { AlertButton } from '../alert/button/alert-button';

interface ErrorAlertPropsInterface {
    email?: string;
}

export const ErrorAlert: React.FC<ErrorAlertPropsInterface> = observer(({ email }: ErrorAlertPropsInterface) => {
    const { t } = useTranslation();
    const { authStore, alertStore } = useRootStore();

    if (!alertStore.errorMessage) {
        return null;
    }
    return (
        <Alert
            type="error"
            onCloseClick={() => {
                alertStore.setErrorMessage('');
            }}
            icon
            loading={alertStore.loading}
        >
            {alertStore.errorMessage}
            {alertStore.errorMessage === t('errors.emailNotConfirmed') && email && (
                <AlertButton
                    onClick={async () => {
                        try {
                            await authStore.sendVerificationEmail(email);
                            alertStore.setErrorMessage('');
                            alertStore.setSuccessMessage(t('layout.alerts.successMessage'));
                        } catch (e) {
                            // eslint-disable-next-line no-console
                            console.error(e);
                        }
                    }}
                >
                    {t('layout.alerts.sendVerificationEmail')}
                </AlertButton>
            )}
        </Alert>
    );
});
