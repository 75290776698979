import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/root-store-provider';
import { Alert } from '../alert/alert';

export const SuccessAlert: React.FC = observer(() => {
    const { alertStore } = useRootStore();
    if (!alertStore.successMessage) {
        return null;
    }
    return (
        <Alert
            onCloseClick={() => {
                alertStore.setSuccessMessage('');
            }}
            type="success"
            icon
        >
            {alertStore.successMessage}
        </Alert>
    );
});
