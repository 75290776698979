import { LayoutEnum } from '@app/common';
import styled, { css, DefaultTheme } from 'styled-components';
import { Wrapper as Icon } from '../../icon/icon.styled';
import { GapSizeType } from '../../../../theme/type/gap-type';

const activeFilledItem = (isActive: boolean, theme: DefaultTheme) => {
    return (
        isActive &&
        css`
            background: ${theme.palette.gradient.primary.main};
            color: ${theme.palette.color.white.main};

            ${Icon} {
                color: inherit;
            }

            &:hover {
                color: ${theme.palette.color.white.main};

                ${Icon} {
                    color: inherit;
                }
            }
        `
    );
};

const activeUnderlinedItem = (isActive: boolean, theme: DefaultTheme) => {
    return (
        isActive &&
        css`
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 0.2rem;
                background: ${theme.palette.gradient.primary.main};
            }
        `
    );
};

const mobileItem = (isActive: boolean, theme: DefaultTheme) => {
    return css`
        padding: 1.6rem ${theme.gap.medium};

        ${activeFilledItem(isActive, theme)};
    `;
};

export const Wrapper = styled.a.withConfig({
    shouldForwardProp: (prop) => !['layout', 'isActive', 'disabled', 'gap'].includes(prop),
})<{
    layout?: LayoutEnum;
    isActive: boolean;
    disabled: boolean;
    gap?: GapSizeType;
}>`
    ${({ theme, layout, isActive, disabled, gap }) => css`
        display: flex;
        align-items: center;
        color: ${theme.palette.common.text};
        font-weight: 700;
        position: relative;
        gap: ${gap ? theme.gap[gap] : 'unset'};
        font-size: ${theme.text.size.medium.fontSize};
        transition: color ${theme.base.transition};
        cursor: pointer;

        ${disabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed;
        `}

        ${Icon} {
            color: #8e89c0;
            width: 2.2rem;
            height: 2.2rem;
            margin-right: 1rem;
        }

        &:hover {
            color: ${theme.palette.common.text};

            ${Icon} {
                color: ${theme.palette.common.text};
            }
        }

        ${layout === LayoutEnum.Horizontal &&
        css`
            @media ${theme.breakpoints.vl.max} {
                ${mobileItem(isActive, theme)};
            }

            @media ${theme.breakpoints.vl.min} {
                padding: 1.3rem ${theme.gap.medium};

                ${activeFilledItem(isActive, theme)};
            }
        `}

        ${layout === LayoutEnum.Vertical &&
        css`
            @media ${theme.breakpoints.vl.max} {
                ${mobileItem(isActive, theme)};
            }

            @media ${theme.breakpoints.vl.min} {
                margin: 0 0.3rem;
                padding: 3.1rem 0.5rem;

                ${activeUnderlinedItem(isActive, theme)}
            }

            @media ${theme.breakpoints.vl.min} and ${theme.breakpoints.xl.max} {
                font-size: ${theme.text.size.small.fontSize};

                ${Icon} {
                    width: 1.6rem;
                    height: 1.6rem;
                    margin-right: 1rem;
                }
            }

            @media ${theme.breakpoints.xl.min} {
                padding: 2.9rem 0.5rem;
            }

            @media ${theme.breakpoints.xxl.min} {
                margin: 0 2rem;
            }
        `}
    `}
`;

export const BadgeWrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['layout', 'isActive'].includes(prop),
})<{
    layout?: LayoutEnum;
    isActive: boolean;
}>`
    ${({ theme, layout, isActive }) => css`
        margin-left: 1rem;

        @media ${theme.breakpoints.vl.min} {
            ${layout === LayoutEnum.Horizontal &&
            css`
                ${isActive &&
                css`
                    display: none;
                `}
            `}

            ${layout === LayoutEnum.Vertical &&
            css`
                margin-left: unset;
                position: absolute;
                right: 0;
                bottom: 1.2rem;
            `}
        }
    `}
`;
