import React from 'react';
import { LayoutEnum } from '@app/common';
import Link from 'next/link';
import { Wrapper, BadgeWrapper } from './item.styled';
import { Badge } from '../../badge/badge';

type MenuItemProps = {
    name: string;
    url?: string | null;
    icon?: React.ReactNode;
    layout?: LayoutEnum;
    isActive?: boolean;
    disabled?: boolean;
    badge?: string | null;
    onClick?: () => void;
};

export const MenuItem: React.FC<MenuItemProps> = ({
    layout = LayoutEnum.Horizontal,
    name,
    url = null,
    icon,
    onClick,
    badge,
    isActive = false,
    disabled = false,
}: MenuItemProps) => {
    const handleOnClick = () => {
        if (disabled) {
            return;
        }

        if (onClick) {
            onClick();
        }
    };

    const a = (
        <Wrapper
            onClick={() => {
                handleOnClick();
            }}
            layout={layout}
            isActive={isActive}
            disabled={disabled}
        >
            {icon}
            {name}
            {badge ? (
                <BadgeWrapper layout={layout} isActive={isActive}>
                    <Badge variant="purple" size="small">
                        {badge}
                    </Badge>
                </BadgeWrapper>
            ) : null}
        </Wrapper>
    );

    if (url && !disabled) {
        return (
            <Link href={url} passHref>
                {a}
            </Link>
        );
    }

    return a;
};
