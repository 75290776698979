import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconLogout: React.FC = () => (
    <Icon viewBox="0 0 24 24" fill="none">
        <line
            x1="7.5"
            y1="12.004"
            x2="23.25"
            y2="12.004"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <polyline
            points="19.5 15.754 23.25 12.004 19.5 8.254"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
        <path
            d="M15.75,16.5V21a1.437,1.437,0,0,1-1.364,1.5H2.113A1.437,1.437,0,0,1,.75,21V3A1.436,1.436,0,0,1,2.113,1.5H14.386A1.437,1.437,0,0,1,15.75,3V7.5"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
        />
    </Icon>
);
