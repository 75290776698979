import { LayoutEnum } from '@app/common';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Wrapper } from './menu.styled';

interface MenuPropsInterface {
    children: Array<JSX.Element>;
    layout?: LayoutEnum;
}

export const Menu: React.FC<MenuPropsInterface> = observer(({ layout = LayoutEnum.Horizontal, children }) => {
    return (
        <Wrapper layout={layout}>
            {children.map((child, index) => {
                return React.cloneElement(child, {
                    layout,
                    key: index,
                });
            })}
        </Wrapper>
    );
});
