import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../../providers/root-store-provider';
import { Alert } from '../alert/alert';

export const WarningAlert: React.FC = observer(() => {
    const { alertStore } = useRootStore();
    if (!alertStore.warningMessage) {
        return null;
    }
    return (
        <Alert type="warning" icon loading={alertStore.loading}>
            {alertStore.warningMessage}
        </Alert>
    );
});
