import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => css`
        padding: 0 ${theme.gap.small};

        @media ${theme.breakpoints.md.min} {
            padding: 0 ${theme.gap.medium};
            margin: 0 auto;
            max-width: ${theme.base.containerWidth};
            width: 100%;
        }
    `}
`;
