import { LayoutEnum } from '@app/common';
import styled, { css } from 'styled-components';

export const Wrapper = styled.nav.withConfig({
    shouldForwardProp: (prop) => !['layout'].includes(prop),
})<{
    layout: LayoutEnum;
}>(
    ({ theme, layout }) => css`
        display: flex;
        margin: 0;
        padding: 0;

        ${layout === LayoutEnum.Horizontal &&
        css`
            flex-direction: column;
        `}

        ${layout === LayoutEnum.Vertical &&
        css`
            flex-direction: column;

            @media ${theme.breakpoints.vl.min} {
                flex-direction: row;
                align-items: center;
            }
        `}
    `,
);
