import React from 'react';
import { Wrapper } from './badge.styled';
import { PaletteColorType } from '../../../theme/type/palette-type';

interface BadgePropsInterface {
    children: React.ReactNode;
    variant?: PaletteColorType;
    size?: 'small' | 'medium' | 'large';
    square?: boolean;
}

export const Badge: React.FC<BadgePropsInterface> = ({
    children,
    variant = 'default',
    size = 'medium',
    square,
}: BadgePropsInterface) => {
    return (
        <Wrapper variant={variant} size={size} square={square}>
            {children}
        </Wrapper>
    );
};
