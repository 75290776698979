import { LayoutEnum } from '@app/common';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['layout', 'showBar'].includes(prop),
})<{
    layout: LayoutEnum;
    showBar: boolean;
}>(
    ({ theme, layout, showBar }) => css`
        position: fixed;
        top: 10rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 920;

        ${layout === LayoutEnum.Horizontal &&
        css`
            top: 2rem;

            ${showBar &&
            css`
                top: calc(${theme.base.barHeight} + 2rem);
            `}
        `}

        ${layout === LayoutEnum.Vertical &&
        css`
            top: 10rem;

            ${showBar &&
            css`
                top: calc(${theme.base.barHeight} + 10rem);
            `}
        `}
    `,
);
